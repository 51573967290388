<template>
  <div>
    <div class="surroundContainer">
      <div class="header">
        <div class="icon"></div>
        <div class="title">粪污加工累计量分析</div>
      </div>
      <div class="chart-box">
        <div ref="chart" class="chart"></div>
        <div class="unit">
          <div class="query">
            <div style="display:flex;" class="cele">
              <div
                v-for="(item, i) in chooselist"
                :key="i"
                :class="item.isSelect ? 'btnactive' : 'btn'"
                @click="checkInfo(i)"
                style="cursor: pointer"
              >
                {{ item.text }}
              </div>
            </div>
            <Select v-model="year" class="y-select" size="small">
              <Option
                v-for="item in years"
                :value="item.value"
                :key="item.value"
                >{{ item.label }}</Option
              >
            </Select>
          </div>

          <span> 单位： {{ unit }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import moment from "moment";
export default {
  props: {
    currentFarm: Object,
  },
  data() {
    let year = moment().year();
    let years = [];
    for (let i = 5; i > 0; i--) {
      years.push({
        key: year - i,
        value: year - i,
        label: year - i,
      });
    }
    for (let i = 0; i < 5; i++) {
      years.push({
        key: year + i,
        value: year + i,
        label: year + i,
      });
    }
    return {
      unit: "",
      datas: [],
      type: "",
      year: 2022,
      years: years,
      chooselist: [
        { text: "日", id: "1", isSelect: false, type: "day" },
        { text: "月", id: "2", isSelect: false, type: "month" },
        { text: "年", id: "3", isSelect: true, type: "year" },
      ],
      currIndexRecommend: Number,
    };
  },
  methods: {
    checkInfo(currIndexRecommend) {
      let id = this.chooselist[currIndexRecommend].id;
      let comboList = this.chooselist;
      for (let i = 0; i < comboList.length; i++) {
        comboList[i].isSelect = i == currIndexRecommend;
      }
      this.chooselist = comboList;
      this.type = this.chooselist[currIndexRecommend].type;
      this.getStaticss().then(() => {
        this.initChart();
      });
    },
    //   初始化
    initChart() {
      let chart = echarts.init(this.$refs.chart);
      let options = {
        grid: {
          containLabel: true,
          top: 32,
          left: 25,
          right: 32,
          bottom: 16,
        },
        tooltip: {
          show: true,
          trigger: "axis",
          backgroundColor: "rgba(12, 12, 13, 0.4)",
          borderColor: "rgba(12, 12, 13, 0.4)",
          borderWidth: 0,
          textStyle: {
            color: "#FFFFFF",
          },
          axisPointer: {
            type: "line",
            lineStyle: {
              color: "#7E91AE",
              type: "solid",
            },
          },
          formatter: (params, ticket) => {
            let d = params[0];
            let icon = `<div style="display:flex;">日累计量&nbsp;&nbsp;&nbsp;&nbsp;${d.data}${this.unit}</div>`;
            return `<div style="font-size:12px;font-weight:400;">${this.year}年02月07日<br>${icon}</div>`;
          },
        },
        xAxis: {
          data: this.datas.map((item) => item.time),
          axisLabel: {
            color: "#fff",
          },
          boundaryGap: false,
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: ["#7E91AE"],
              type: "solid",
            },
          },
        },
        yAxis: {
          nameTextStyle: {
            color: "#fff",
          },
          axisLabel: {
            color: "#fff",
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ["#7E91AE"],
              type: "solid",
            },
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            smooth: true,
            type: "line",
            data: this.datas.map((item) => item.val),
            lineStyle: {
              color: "rgba(54, 242, 255, 1)",
            },
            itemStyle: {
              color: "rgba(54, 242, 255, 1)",
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(54, 242, 255, 0.67)",
                },
                {
                  offset: 0.8,
                  color: "rgba(69, 21, 182, 0)",
                },
              ]),
            },
          },
        ],
      };
      chart.setOption(options);
    },
    getStatics() {
      this.datas = [];
      return this.$post(this.$api.STATICS_MANAGE.GET2, {
        resourceTypeId: 129,
      }).then((res) => {
        res.forEach((item) => {
          this.datas.push({
            time: item.dimensionName,
            val: item.content,
          });
        });
        // this.unit = res[0].contentUnit;
        this.unit = "吨";
      });
    },
    getStaticss() {
      this.datas = [];
      return this.$post(this.$api.STATICTICS_MAP.AMOUNT, {
        type: this.type,
      }).then((res) => {
        let timeLine = res.timeLine;
        for (let key in timeLine) {
          this.datas.push({
            time: key,
            val: timeLine[key],
          });
        }
      });
    },
  },
  mounted() {
    this.getStatics().then(() => {
      this.initChart();
    });
    // this.getStaticss().then(() => {
    //   this.initChart();
    // });
  },
};
</script>
<style lang="less" scoped>
@import url("../common.less");

.unit {
  position: absolute;
  width: 95%;
  left: 16px;
  top: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 15px;
  color: #b2bed1;
  font-size: 12px;
  .cele {
    border: 1px solid #343e4f;
    border-radius: 5px;
  }
  .btn {
    width: 35px;
    height: 25px;
    //   border: 1px solid #343e4f;
    text-align: center;
    //   margin-right: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .btnactive {
    width: 35px;
    height: 25px;
    border: 1px solid #00fff7;
    text-align: center;
    //   margin-right: 5px;
    color: #00fff7;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 5px;
  }
  .right-top {
    width: 8px;
    height: 8px;
    border-top: 2px solid #00fff7;
    border-right: 2px solid #00fff7;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .right-bottom {
    width: 8px;
    height: 8px;
    border-bottom: 2px solid #00fff7;
    border-right: 2px solid #00fff7;
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
  .left-bottom {
    width: 16px;
    height: 16px;
    border-bottom: 2px solid #00fff7;
    border-left: 2px solid #00fff7;
    position: absolute;
    left: 0px;
    bottom: 0px;
  }
  .left-top {
    width: 8px;
    height: 8px;
    border-top: 2px solid #00fff7;
    border-right: 2px solid #00fff7;
    position: absolute;
    right: 0px;
    top: 0px;
  }
}

.query {
  display: flex;
  margin-right: 21px;
}
.y-select {
  width: 78px;
  height: 28px;
  color: #dae1eb;
  font-size: 12px;
  margin: 0 0 0 12px;
  /deep/ .ivu-select-selection {
    background: transparent !important;
  }
}
</style>
